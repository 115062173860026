// src/components/Header.js
import React, { useEffect } from "react";
import logo from "../../images/logo192.png"; // Replace with your actual logo path

const Header = () => {
  useEffect(() => {
    // Load Google Tag
    const googleScript = document.createElement("script");
    googleScript.async = true;
    googleScript.src = `https://www.googletagmanager.com/gtag/js?id=YOUR_GOOGLE_MEASUREMENT_ID`;
    document.head.appendChild(googleScript);

    googleScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag("js", new Date());
      gtag("config", "YOUR_GOOGLE_MEASUREMENT_ID");
    };

    // Load Facebook Pixel
    const fbScript = document.createElement("script");
    fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', 'YOUR_FACEBOOK_PIXEL_ID'); 
      fbq('track', 'PageView');
    `;
    document.head.appendChild(fbScript);
  }, []);

  return (
    <header className="container mx-auto px-4 py-6 md:py-8">
      <div className="flex flex-col md:flex-row items-center justify-between">
        {/* Logo */}
        <div className="flex items-center space-x-3">
          <img src={logo} alt="Logo" className="h-12 md:h-14" />
          <span className="text-blue-900 text-xl md:text-3xl font-bold">
            USA Insurance Reviews
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
