import React, { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import Header from "./Header";
import Footer from "./Footer";
import ProgressBar from "./ProgressBar";

const MobileNumberForm = ({ onNext }) => {
  const steps = ["Current Insurer", "About You", "Quotes"];
  const [currentStep, setCurrentStep] = useState(1);
  const { isLoading, handleNavigation } = useNavigationHandler();
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");

  // ✅ Load saved mobile number from local storage
  useEffect(() => {
    const savedMobileNumber = localStorage.getItem("mobileNumber");
    if (savedMobileNumber) setMobileNumber(savedMobileNumber);
  }, []);

  // ✅ Save mobile number to local storage
  useEffect(() => {
    localStorage.setItem("mobileNumber", mobileNumber);
  }, [mobileNumber]);

  // ✅ Format mobile number as xxx-xxx-xxxx
  const formatMobileNumber = (value) => {
    const cleaned = value.replace(/\D/g, ""); // Remove all non-digit characters
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (!match) return "";
    return `${match[1]}${match[2] ? "-" : ""}${match[2]}${match[3] ? "-" : ""}${
      match[3]
    }`;
  };

  // ✅ Handle Input Change
  const handleChange = (e) => {
    const formattedNumber = formatMobileNumber(e.target.value);
    setMobileNumber(formattedNumber);

    // Auto-validate while typing
    if (formattedNumber.replace(/\D/g, "").length === 10) {
      setError("");
    }
  };

  // ✅ Validate mobile number
  const validateMobileNumber = () => {
    const cleaned = mobileNumber.replace(/\D/g, "");
    if (!cleaned) {
      setError("Mobile number is required");
      return false;
    }
    if (cleaned.length !== 10) {
      setError("Please enter a valid 10-digit mobile number");
      return false;
    }
    setError("");
    return true;
  };

  // ✅ Handle Next Step
  const handleNext = () => {
    if (validateMobileNumber()) {
      onNext();
    }
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[60vh] bg-white px-4 py-10 fade-in relative">
        <ProgressBar steps={steps} currentStep={1} progress={75} />

        <div className="text-center mt-6">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            What is your mobile number?
          </h1>
          <p className="text-gray-500 mt-2">
            You'll receive a 6-digit verification code to create your account.
          </p>
        </div>

        {/* ✅ Mobile Number Input Field */}
        <div className="mt-8 w-full max-w-sm relative">
          <input
            type="tel"
            placeholder="Mobile number"
            value={mobileNumber}
            onChange={handleChange}
            onBlur={validateMobileNumber}
            maxLength="12" // Ensures the format is xxx-xxx-xxxx
            className={`w-full border rounded-lg px-4 py-3 text-gray-700 focus:outline-none text-lg ${
              error
                ? "border-red-500 focus:ring-2 focus:ring-red-500"
                : "border-pink-500 focus:ring-2 focus:ring-pink-500"
            }`}
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        {/* ✅ Privacy Notice */}
        <div className="mt-6 w-full max-w-sm bg-gray-100 rounded-lg px-4 py-4 text-left text-sm leading-relaxed">
          <p className="text-gray-900 font-medium">
            We Care About Your Privacy
          </p>
          <p className="text-gray-500 mt-2">
            By clicking "Next Step", you consent in writing to receive automated
            marketing text messages, calls, and pre-recorded messages from
            Experian Insurance Services at the phone number provided above. You
            can unsubscribe at any time by replying "STOP". Consent is not a
            condition of purchase, and standard text message rates apply. See
            our{" "}
            <a href="#" className="text-blue-600 underline">
              Terms of Use
            </a>
            .
          </p>
        </div>

        {/* ✅ Buttons */}
        <div className="mt-8 flex gap-4">
          <button
            onClick={() => handleNavigation("/email-form")}
            className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
          >
            Back
          </button>

          <button
            onClick={() => handleNavigation("/loading-screen")}
            className={`px-6 py-3 rounded-lg w-40 transition-all duration-300 ${
              mobileNumber.replace(/\D/g, "").length === 10 && !error
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={mobileNumber.replace(/\D/g, "").length !== 10 || error}
          >
            Next Step
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MobileNumberForm;
