import React from "react";
import happyMan from "../../images/insurance1.jpeg"; // Adjust the path if necessary
import useNavigationHandler from "../hooks/useNavigationHandler";

const Hero = () => {
  const { isLoading, handleNavigation } = useNavigationHandler(); // Get loading state and function

  return (
    <div className="relative min-h-screen">
      {/* Background Image Container */}
      <div className="relative overflow-hidden min-h-[120vh]">
        <img
          src={happyMan}
          alt="Happy man driving"
          className="w-full h-full object-cover"
          style={{
            objectPosition: "center top",
            transform: "scale(1.5)", // Adjust scale to extend image
            transformOrigin: "center",
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            background: `linear-gradient(to bottom left,
                rgba(23, 56, 124, 0.6),   
                rgb(6, 39, 107)  
              )`,
          }}
        ></div>
      </div>

      {/* Content Overlay */}
      <div className="absolute inset-0 flex flex-col justify-start items-start text-white px-6 pt-6">
        <h1 className="text-2xl md:text-4xl lg:text-6xl font-medium mb-4 leading-snug text-left text-white lg:mt-20 lg:ml-10 tracking-wide">
          Stop overpaying for <br></br>car insurance
        </h1>
        <p className="lg:text-2xl text-left mb-6 lg:mw-3 lg:mt-4 lg:ml-10 md:w-2/3 lg:w-2/5">
          You could save over $800 a year by letting us monitor, shop and
          compare quotes for you to get our best rate—for free.
        </p>
        <img
          alt="traveler insurance quote"
          className="relative lg:absolute lg:translate-x-2/3 lg:translate-y-2/3 lg:w-1/3 lg:h-1/3 lg:ml-80"
          src="https://assets.experiancs.com/images/graphic-auto-savings-comparison.webp?hs=ece7b1e75d569f0af542f5c4d8d80972"
          height="397"
          width="558"
        ></img>
        <button
          id="get-started-btn"
          className="bg-pink-600 text-white lg:py-4 lg:px-14 py-3 px-8 rounded text-lg font-semibold shadow-lg hover:bg-pink-600 transition duration-300 mt-6 lg:ml-10 lg:mt-3"
          onClick={() => handleNavigation("/current-insurer")}
        >
          Get Started
        </button>
        <p className="mt-4 text-med text-center lg:text-left mt-6 lg:ml-10">
          <p>Already a member?</p>{" "}
          <a href="#" className="underline hover:text-white">
            Sign In
          </a>{" "}
          to access additional features like rate monitoring.
        </p>
        <h2 className="text-3xl font-medium text-white text-left mt-5 lg:mt-10 lg:ml-10">
          Why USA Insurance?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:ml-10">
          <div className="flex items-start space-x-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 10h11M9 21V3m6 7h6m-6 4h6m-6 4h6"
                />
              </svg>
            </div>
            <p className="lg:text-2xl text-white">
              We shop and compare quotes for you from over 40 top insurers.
            </p>
          </div>
          <div className="flex items-start space-x-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </div>
            <p className="lg:text-2xl text-white">
              Easily switch your coverage online or with an agent over the
              phone.
            </p>
          </div>
          <div className="flex items-start space-x-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 16l4-4m0 0l4-4m-4 4H3m11 0h8"
                />
              </svg>
            </div>
            <p className="lg:text-2xl text-white">
              No spam calls—we’ll only contact you with permission to help you
              save on insurance.
            </p>
          </div>
          <p className="text-med mt-4 lg:w-1/2">
            Results will vary and some may not see savings. Average savings of
            $828 per year for customers who switched and saved with USA
            Insurance Reviews from Jan. 1, 2022 to Mar. 31, 2024. Savings based
            on customers’ self-reported prior premium. USA Insurance Reviews
            offers insurance from a network of top-rated insurance companies
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
