import React from "react";

const Reviews = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-6 md:px-12 lg:px-16">
        {/* Top Section: Verified Reviews and Rating */}
        <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left mb-12">
          {/* Left Side: Title */}
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
            Verified User Reviews
          </h2>

          {/* Right Side: Rating and Stars */}
          <div className="flex flex-col md:flex-row items-center md:items-end text-gray-900 mt-6 md:mt-0">
            <div className="flex items-center">
              <span className="text-6xl font-bold text-blue-900">4.8</span>
              <div className="ml-3 flex">
                {/* Star Ratings */}
                {[...Array(5)].map((_, index) => (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 .587l3.668 7.431L24 9.751l-6 5.847 1.418 8.266L12 18.902l-7.418 4.962L6 15.598 0 9.751l8.332-1.733z" />
                  </svg>
                ))}
              </div>
            </div>
            <p className="text-gray-600 mt-2 md:ml-4">
              <strong>3,666</strong> 3rd Party Verified Reviews
            </p>
          </div>
        </div>

        {/* Bottom Section: User Testimonials */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            {
              name: "Olivia T.",
              savings: "$322",
              image:
                "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg",
              review:
                "As a school teacher, it's difficult to find time during the day to call multiple insurance agents. After a simple signup step, they did all the rest to get me the best rate.",
            },
            {
              name: "Barry B.",
              savings: "$795",
              image:
                "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
              review:
                "Switching from my previous insurer was very easy. They saved me around $800 a year! I recommend to anyone who is looking to save money on their insurance needs.",
            },
            {
              name: "Sophia L.",
              savings: "$410",
              image:
                "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg",
              review:
                "As a busy professional, I appreciated how effortless the process was. They found me a policy that fits my needs and budget perfectly.",
            },
          ].map((user, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300"
            >
              <div className="flex items-center mb-4">
                <div className="w-14 h-14 rounded-full overflow-hidden">
                  <img
                    src={user.image}
                    alt={user.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-lg font-bold text-pink-600">{user.name}</p>
                  <p className="text-sm text-gray-500">Saved {user.savings}</p>
                </div>
              </div>
              <blockquote className="text-gray-700 italic">{`"${user.review}"`}</blockquote>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
