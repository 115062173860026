import React, { useState, useEffect } from "react";

const logos = [
  {
    src: "https://assets.experiancs.com/images/logo-progressive-alt.svg?hs=4ba1428fdc32fc4036ad49398cb2f983",
    alt: "Progressive",
  },
  {
    src: "https://assets.experiancs.com/images/logo-liberty-mutual.svg?hs=9aae2a13a87c6045469575010467c96b",
    alt: "Liberty Mutual",
  },
  {
    src: "https://assets.experiancs.com/images/logo-nationwide.svg?hs=de940956eb9787b3af0853efa2c9d911",
    alt: "Nationwide",
  },
  {
    src: "https://assets.experiancs.com/images/logo-mercury.svg?hs=e3a9c99be5ed99da73c80fa514f2a3bb",
    alt: "Mercury Insurance",
  },
];

const Logos = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const keyframes = `
    @keyframes scroll {
      0% { transform: translateX(0); }
      100% { transform: translateX(-50%); }
    }
  `;

  return (
    <section
      style={{
        backgroundColor: "#F9FAFB",
        padding: "3rem 0",
        overflow: "hidden",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
        <h3
          style={{ fontSize: "1.25rem", fontWeight: "bold", color: "#4B5563" }}
        >
          Compare rates from over 40 top insurers
        </h3>
      </div>

      {/* Injecting Keyframes for Small Screens */}
      {isSmallScreen && <style>{keyframes}</style>}

      <div style={{ overflow: "hidden", width: "100%" }}>
        {isSmallScreen ? (
          // Scrolling Effect on Small Screens
          <div
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              animation: "scroll 12s linear infinite",
            }}
          >
            {[...logos, ...logos].map((logo, index) => (
              <img
                key={index}
                src={logo.src}
                alt={logo.alt}
                style={{ height: "3rem", flexShrink: 0, marginRight: "3rem" }}
              />
            ))}
          </div>
        ) : (
          // Centered Logos on Large Screens
          <div
            style={{ display: "flex", justifyContent: "center", gap: "3rem" }}
          >
            {logos.map((logo, index) => (
              <img
                key={index}
                src={logo.src}
                alt={logo.alt}
                style={{ height: "3rem", flexShrink: 0 }}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Logos;
