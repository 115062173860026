import React, { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

const NameForm = ({ onNext }) => {
  const { isLoading, handleNavigation } = useNavigationHandler();
  const steps = ["Current Insurer", "About You", "Quotes"];
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [error, setError] = useState("");

  // ✅ Load First Name from session storage
  useEffect(() => {
    const savedFirstName = sessionStorage.getItem("firstName");
    if (savedFirstName) setFirstName(savedFirstName);
  }, []);

  // ✅ Load Birthdate from session storage
  useEffect(() => {
    const savedBirthdate = sessionStorage.getItem("birthdate");
    if (savedBirthdate) setBirthdate(savedBirthdate);
  }, []);

  // ✅ Save Birthdate to session storage
  useEffect(() => {
    if (birthdate) {
      sessionStorage.setItem("birthdate", birthdate);
    }
  }, [birthdate]);

  // ✅ Validate Birthdate (Format: MM/DD/YYYY)
  // ✅ Validate Birthdate (Format: MM/DD/YYYY) & Age Limit
  const validateBirthdate = (date) => {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!date) {
      setError("Birthdate is required.");
      return false;
    } else if (date.length < 10) {
      setError("Date is too short.");
      return false;
    } else if (!regex.test(date)) {
      setError("Invalid date format (MM/DD/YYYY).");
      return false;
    }

    // ✅ Convert string to Date object
    const [month, day, year] = date.split("/").map(Number);
    const birthDateObj = new Date(year, month - 1, day); // Month is 0-based in JS Date()

    // ✅ Check if the date is valid (e.g., Feb 30 should be rejected)
    if (
      birthDateObj.getFullYear() !== year ||
      birthDateObj.getMonth() !== month - 1 ||
      birthDateObj.getDate() !== day
    ) {
      setError("Invalid date. Please enter a real date.");
      return false;
    }

    // ✅ Get today's date
    const today = new Date();
    const age = today.getFullYear() - birthDateObj.getFullYear();

    // ✅ Check if the birthdate is more than 90 years ago
    if (age > 90) {
      setError("Please enter a valid date.");
      return false;
    }

    if (age < 15) {
      setError("Please enter a valid date.");
      return false;
    }

    // ✅ Check if birthdate is in the future
    if (birthDateObj > today) {
      setError("Please enter a valid date.");
      return false;
    }

    setError(""); // Clear error if valid
    return true;
  };

  // ✅ Handle Input With Auto-Formatting and Validation (Fixed Bug)
  const handleInputChange = (e) => {
    let value = e.target.value;

    // ✅ Check if input is an alphabet
    if (/[a-zA-Z]/.test(value)) {
      setError("Invalid date format (MM/DD/YYYY).");
      return;
    } else {
      setError("");
    }

    // ✅ Allow only numbers
    value = value.replace(/\D/g, ""); // Remove non-numeric characters

    // ✅ Auto-add '/' at correct positions (Fixed Issue)
    if (value.length > 2 && value.length <= 4) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length > 4) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
    }

    // ✅ Limit to 10 characters (MM/DD/YYYY)
    if (value.length <= 10) {
      setBirthdate(value);
    }
  };

  const handleNext = () => {
    if (validateBirthdate(birthdate)) {
      onNext();
    }
  };

  // ✅ Enable button only when birthdate is valid
  const isNextStepEnabled = birthdate.trim() !== "" && error === "";

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[80vh] bg-white px-4 py-10 fade-in relative">
        {/* Progress Bar */}
        <ProgressBar steps={steps} currentStep={1} progress={40} />

        {/* Main Content */}
        <div className="text-center mt-6">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            When were you born {firstName}?
          </h1>
          <p className="text-gray-500 mt-2">
            We need this information to provide an accurate quote.
          </p>
        </div>

        {/* Birthdate Input With Error */}
        <div className="mt-8 w-full max-w-sm relative">
          <input
            type="text"
            placeholder="Birthdate (MM/DD/YYYY)"
            value={birthdate}
            onChange={handleInputChange}
            onBlur={() => validateBirthdate(birthdate)}
            className={`w-full border rounded-lg px-4 py-3 text-gray-700 focus:outline-none text-lg ${
              error
                ? "border-red-500 focus:ring-2 focus:ring-red-500"
                : "border-pink-300 focus:ring-2 focus:ring-pink-400"
            }`}
          />
          {/* Error Icon */}
          {error && (
            <div className="absolute right-4 top-3 text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v2m0 4h.01m6.938-7.938a8 8 0 11-11.314 0 8 8 0 0111.314 0z"
                />
              </svg>
            </div>
          )}
          {/* Error Message */}
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        {/* Buttons */}
        <div className="mt-8 flex gap-4">
          <button
            onClick={() => handleNavigation(-1)}
            style={{ border: "1px solid #D1D5DB" }}
            className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
          >
            Back
          </button>

          <button
            onClick={() => handleNavigation("/address-form")}
            className={`px-6 py-3 rounded-lg w-40 transition-all duration-300 ${
              isNextStepEnabled
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={!isNextStepEnabled}
          >
            Next Step
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NameForm;
