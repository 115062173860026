import React, { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

// Sample vehicle data (normally from API)
const carData = {
  2024: ["Toyota", "Honda", "Ford"],
  2023: ["Tesla", "Chevrolet", "Nissan"],
  2022: ["BMW", "Audi", "Mercedes"],
};

const modelsData = {
  Toyota: ["Camry", "Corolla", "RAV4"],
  Honda: ["Civic", "Accord", "CR-V"],
  Ford: ["F-150", "Mustang", "Explorer"],
  Tesla: ["Model S", "Model 3", "Model X"],
  Chevrolet: ["Malibu", "Tahoe", "Silverado"],
  Nissan: ["Altima", "Sentra", "Rogue"],
  BMW: ["X5", "X3", "M3"],
  Audi: ["A4", "Q5", "A6"],
  Mercedes: ["C-Class", "E-Class", "GLE"],
};

const CarDetailsForm = ({ onNext }) => {
  const steps = [
    "Cars",
    "Drivers",
    "Discounts",
    "Driving History",
    "Current Policy",
    "Coverage",
  ];
  const { handleNavigation } = useNavigationHandler();

  // Load saved selections from localStorage
  const [modelYear, setModelYear] = useState(
    localStorage.getItem("modelYear") || ""
  );
  const [make, setMake] = useState(localStorage.getItem("make") || "");
  const [model, setModel] = useState(localStorage.getItem("model") || "");

  // Update localStorage when values change
  useEffect(() => {
    localStorage.setItem("modelYear", modelYear);
    localStorage.setItem("make", make);
    localStorage.setItem("model", model);
  }, [modelYear, make, model]);

  return (
    <div>
      <Header />
      <div className="flex flex-col md:flex-row min-h-[60vh]">
        {/* Sidebar (Hidden on Mobile) */}
        <div className="w-full md:w-1/4 bg-gray-50 p-6 border-r hidden md:block">
          <h2 className="text-xl font-semibold mb-6">Auto insurance</h2>
          <ul className="space-y-4">
            {steps.map((step, index) => (
              <li
                key={index}
                className="flex items-center space-x-2 text-gray-700"
              >
                <div className="w-4 h-4 border border-gray-400 rounded-full flex items-center justify-center text-xs">
                  {index === 0 && (
                    <div className="w-3 h-3 bg-gray-700 rounded-full"></div>
                  )}
                </div>
                <span>{step}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Form Content */}
        <div className="w-full md:w-3/4 p-6">
          <ProgressBar steps={steps} currentStep={0} progress={20} />
          <h1 className="text-3xl font-semibold mt-6 mb-4">Cars</h1>
          <p className="text-gray-700 mb-4">
            Enter some details about your car
          </p>

          {/* Responsive Form Inputs */}
          <div className="flex flex-col md:flex-row gap-4 mb-4">
            {/* Model Year Dropdown */}
            <select
              value={modelYear}
              onChange={(e) => {
                setModelYear(e.target.value);
                setMake("");
                setModel("");
              }}
              className="w-full md:w-1/3 border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              <option value="">Model Year</option>
              {Object.keys(carData).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            {/* Vehicle Make Dropdown */}
            <select
              value={make}
              onChange={(e) => {
                setMake(e.target.value);
                setModel("");
              }}
              disabled={!modelYear}
              className="w-full md:w-1/3 border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              <option value="">Vehicle Make</option>
              {modelYear &&
                carData[modelYear]?.map((brand) => (
                  <option key={brand} value={brand}>
                    {brand}
                  </option>
                ))}
            </select>

            {/* Vehicle Model Dropdown */}
            <select
              value={model}
              onChange={(e) => setModel(e.target.value)}
              disabled={!make}
              className="w-full md:w-1/3 border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500"
            >
              <option value="">Vehicle Model</option>
              {make &&
                modelsData[make]?.map((modelName) => (
                  <option key={modelName} value={modelName}>
                    {modelName}
                  </option>
                ))}
            </select>
          </div>

          {/* VIN Alternative Entry */}
          <div className="bg-gray-100 rounded-lg p-4 w-full mb-6">
            <h2 className="font-semibold">Don’t see your vehicle?</h2>
            <p className="text-gray-500 mt-1">
              Some vehicles may not be available to add by make and model, but
              you can try adding it with a VIN number.
            </p>
            <a href="#" className="text-blue-600 font-medium hover:underline">
              Add with VIN
            </a>
          </div>

          {/* Additional Info Box */}
          <div className="bg-gray-100 rounded-lg p-4 w-full mb-6">
            <h2 className="font-semibold">Add all of your vehicles</h2>
            <p className="text-gray-500 mt-1">
              You'll get quotes based on the vehicles you enter here, so make
              sure to add all the ones you need insurance for. The vehicles you
              add can be edited or removed at any time.
            </p>
          </div>

          {/* Buttons */}
          <div className="flex flex-col md:flex-row gap-4">
            <button
              onClick={() => handleNavigation("/previous-page")}
              className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-full md:w-40 hover:bg-gray-100 transition-all duration-300"
            >
              Back
            </button>

            <button
              onClick={() => handleNavigation("/drivers")}
              className={`bg-pink-500 text-white px-6 py-3 rounded-lg w-full md:w-40 transition-all duration-300 ${
                modelYear && make && model
                  ? "hover:bg-pink-600"
                  : "opacity-50 cursor-not-allowed"
              }`}
              disabled={!modelYear || !make || !model}
            >
              Next Step
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CarDetailsForm;
