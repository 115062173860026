import React, { useEffect, useState } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";

const LoadingScreen = ({ nextPage = "/offers" }) => {
  const { isLoading, handleNavigation } = useNavigationHandler();
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // ✅ Start loading transition
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        handleNavigation(nextPage);
      }, 500); // Fade-out duration
    }, 2400);

    return () => clearTimeout(timer);
  }, [handleNavigation, nextPage]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div
        className={`w-full max-w-md bg-white rounded-lg shadow-lg p-8 text-center transition-opacity duration-500 ${
          fadeOut ? "opacity-0" : "opacity-100"
        }`}
      >
        {/* ✅ Animated Spinner */}
        <div className="flex justify-center items-center mb-4">
          <div className="w-16 h-16 flex items-center justify-center animate-spin rounded-full border-4 border-pink-300 border-t-pink-600"></div>
        </div>

        {/* ✅ Text */}
        <h1 className="text-lg font-semibold text-gray-900">
          Finding the best offers for you
        </h1>
        <p className="text-gray-500 mt-2">Analyzing coverage options...</p>

        {/* ✅ Animated Progress Bar */}
        <div className="w-full h-1 bg-gray-200 rounded-full mt-4 overflow-hidden">
          <div className="h-full bg-pink-600 animate-progress"></div>
        </div>

        {/* ✅ Footer */}
        <div className="mt-6 bg-gray-100 text-gray-500 rounded-lg py-3 text-sm">
          Please wait while we check for savings...
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
