import React, { useState, useEffect, useRef } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

const GOOGLE_MAPS_API_KEY = "YOUR_GOOGLE_MAPS_API_KEY"; // 🔹 Replace with your API key

const ConfirmAddressForm = ({ onNext }) => {
  const steps = [
    "Address",
    "Cars",
    "Drivers",
    "Discounts",
    "Driving History",
    "Current Policy",
    "Coverage",
  ];
  const { handleNavigation } = useNavigationHandler();
  const addressInputRef = useRef(null);

  const [address, setAddress] = useState({
    fullAddress: localStorage.getItem("address") || "",
    unit: localStorage.getItem("unit") || "",
  });

  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  // ✅ Function to initialize Google Places Autocomplete
  const initializeAutocomplete = () => {
    if (!addressInputRef.current) return;

    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps API not loaded");
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInputRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: "us" }, // 🔹 Restrict to US addresses
      }
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      // ✅ Extract and set address
      const formattedAddress = place.formatted_address || "";
      setAddress((prev) => ({
        ...prev,
        fullAddress: formattedAddress,
      }));
      localStorage.setItem("address", formattedAddress);
    });
  };

  // ✅ Load Google Places API dynamically
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google && window.google.maps) {
        initializeAutocomplete();
        return;
      }
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    };

    loadGoogleMapsScript();
  }, []);

  // ✅ Reinitialize Autocomplete when entering Edit mode
  useEffect(() => {
    if (isEditing) {
      setTimeout(initializeAutocomplete, 300); // Small delay ensures input is rendered before attaching
    }
  }, [isEditing]);

  // ✅ Save address & unit when changed
  useEffect(() => {
    localStorage.setItem("address", address.fullAddress);
  }, [address.fullAddress]);

  useEffect(() => {
    localStorage.setItem("unit", address.unit);
  }, [address.unit]);

  // ✅ Handle Manual Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Validate Form
  const validateForm = () => {
    if (!address.fullAddress.trim()) {
      setError("Please provide a valid address.");
      return false;
    }
    setError("");
    return true;
  };

  // ✅ Handle Next Step
  const handleNext = () => {
    if (validateForm()) {
      onNext();
      handleNavigation("/cars");
    }
  };

  return (
    <div>
      <Header />
      <div className="flex min-h-[60vh]">
        {/* Sidebar */}
        <div className="w-1/4 bg-gray-50 p-6 border-r hidden md:block">
          <h2 className="text-xl font-semibold mb-6">Auto insurance</h2>
          <ul className="space-y-4">
            {steps.map((step, index) => (
              <li
                key={index}
                className="flex items-center space-x-2 text-gray-700"
              >
                <div className="w-4 h-4 border border-gray-400 rounded-full flex items-center justify-center text-xs">
                  {index === 0 && (
                    <div className="w-3 h-3 bg-gray-700 rounded-full"></div>
                  )}
                </div>
                <span>{step}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Form Content */}
        <div className="w-full md:w-3/4 p-6">
          <ProgressBar steps={steps} currentStep={0} progress={10} />
          <h1 className="text-3xl font-semibold mt-6 mb-4">Address</h1>
          <p className="text-gray-700 mb-4">Where is your primary residence?</p>

          {/* ✅ Address Display */}
          {!isEditing ? (
            <div className="border rounded-lg p-4 mb-4 flex justify-between items-center">
              <div>
                <p className="font-bold text-lg">
                  {address.fullAddress || "Address not available"}
                </p>
                {address.unit && (
                  <p className="text-gray-500">Unit: {address.unit}</p>
                )}
              </div>
              <button
                onClick={() => setIsEditing(true)}
                className="border border-gray-400 px-4 py-1 rounded-lg hover:bg-gray-100"
              >
                Edit
              </button>
            </div>
          ) : (
            <div className="mb-4 space-y-4">
              {/* ✅ Google Autocomplete Address Input */}
              <input
                ref={addressInputRef}
                type="text"
                name="fullAddress"
                value={address.fullAddress}
                onChange={handleChange}
                placeholder="Street, City, State, ZIP"
                className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
              <input
                type="text"
                name="unit"
                value={address.unit}
                onChange={handleChange}
                placeholder="Unit (optional)"
                className="w-full border border-gray-300 rounded-lg p-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
              <div className="flex gap-4">
                <button
                  onClick={() => setIsEditing(false)}
                  className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-100 transition-all duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="bg-pink-500 text-white px-6 py-3 rounded-lg hover:bg-pink-600 transition-all duration-300"
                >
                  Save
                </button>
              </div>
            </div>
          )}

          {error && <p className="text-red-500 mb-4">{error}</p>}

          {/* ✅ Buttons */}
          <div className="flex gap-4">
            <button
              onClick={() => handleNavigation("/previous-page")}
              className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
            >
              Back
            </button>

            <button
              onClick={handleNext}
              className="bg-pink-500 text-white px-6 py-3 rounded-lg w-40 hover:bg-pink-600 transition-all duration-300"
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ConfirmAddressForm;
