import React from "react";

const ChoosePolicies = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center justify-center lg:justify-between px-6 md:px-12 lg:px-16">
        {/* Text Section */}
        <div className="text-center lg:text-left lg:w-1/2">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 leading-snug mb-4">
            Choose policies from over <br className="hidden md:block" /> 40 top
            insurers
          </h2>
          <p className="text-gray-700 text-lg md:text-xl max-w-lg">
            We make it simple to find the best insurance options with trusted
            providers.
          </p>
        </div>

        {/* Image Section */}
        <div className="relative lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
          <img
            src="https://assets.experiancs.com/images/graphic-top-insurers.webp?hs=30190f323a3e9e1d7dea9048675feaac"
            alt="Top Insurers"
            className="w-full max-w-xs md:max-w-md lg:max-w-xl shadow-lg rounded-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
    </section>
  );
};

export default ChoosePolicies;
