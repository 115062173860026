import React, { useState, useEffect, useRef } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

const GOOGLE_MAPS_API_KEY = "YOUR_GOOGLE_MAPS_API_KEY"; // 🔹 Replace with your API key

const AddressForm = ({ onNext }) => {
  const steps = ["Current Insurer", "About You", "Quotes"];
  const { isLoading, handleNavigation } = useNavigationHandler();
  const [currentStep, setCurrentStep] = useState(1);
  const [address, setAddress] = useState(localStorage.getItem("address") || "");
  const [unit, setUnit] = useState(localStorage.getItem("unit") || "");
  const [homeOwnership, setHomeOwnership] = useState(
    localStorage.getItem("homeOwnership") || ""
  );
  const addressInputRef = useRef(null);

  // ✅ Load Google Places API dynamically
  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google && window.google.maps) {
        initializeAutocomplete();
        return;
      }
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.body.appendChild(script);
    };

    const initializeAutocomplete = () => {
      if (!addressInputRef.current) return;
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "us" }, // 🔹 Restrict to US
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        // Extract and format address details
        const formattedAddress = place.formatted_address || "";
        setAddress(formattedAddress);
        localStorage.setItem("address", formattedAddress); // ✅ Save address to local storage
      });
    };

    loadGoogleMapsScript();
  }, []);

  // ✅ Save `unit` and `homeOwnership` to local storage whenever they change
  useEffect(() => {
    localStorage.setItem("unit", unit);
  }, [unit]);

  useEffect(() => {
    localStorage.setItem("homeOwnership", homeOwnership);
  }, [homeOwnership]);

  // ✅ Handle Next Step Validation
  const handleNext = () => {
    if (!address.trim()) {
      alert("Please enter a valid address");
      return;
    }
    if (!homeOwnership) {
      alert("Please select home ownership status");
      return;
    }
    onNext();
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[60vh] bg-white px-4 py-10 fade-in relative">
        <ProgressBar steps={steps} currentStep={1} progress={60} />
        <div className="text-center mt-6">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            What is your home address?
          </h1>
          <p className="text-gray-500 mt-2">
            Your address impacts the insurance rates that we can find.
          </p>
        </div>

        {/* ✅ Google Autocomplete Address Input */}
        <div className="mt-8 w-full max-w-sm relative">
          <input
            ref={addressInputRef}
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              localStorage.setItem("address", e.target.value); // ✅ Save to local storage
            }}
            className="w-full border border-gray-300 rounded-lg px-4 py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 text-lg"
          />
        </div>

        {/* ✅ Unit Number (Optional) */}
        {address && (
          <div className="mt-4 w-full max-w-sm">
            <input
              type="text"
              placeholder="Unit number (if applicable)"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 text-lg"
            />
          </div>
        )}

        {/* ✅ Rent/Own Toggle (Now Persists in Local Storage) */}
        {address && (
          <div className="mt-6 w-full max-w-sm flex items-center gap-6">
            <p className="text-gray-700 font-medium whitespace-nowrap">
              Do you own this home?
            </p>

            <div className="inline-flex border border-pink-500 rounded-lg overflow-hidden">
              <button
                className={`px-6 py-3 w-24 text-lg font-medium transition-all duration-300 ${
                  homeOwnership === "Rent"
                    ? "bg-pink-500 text-white"
                    : "bg-white text-gray-700 hover:bg-gray-100"
                }`}
                onClick={() => {
                  setHomeOwnership("Rent");
                  localStorage.setItem("homeOwnership", "Rent"); // ✅ Save home ownership status
                }}
              >
                Rent
              </button>
              <button
                className={`px-6 py-3 w-24 text-lg font-medium transition-all duration-300 ${
                  homeOwnership === "Own"
                    ? "bg-pink-500 text-white"
                    : "bg-white text-gray-700 hover:bg-gray-100"
                }`}
                onClick={() => {
                  setHomeOwnership("Own");
                  localStorage.setItem("homeOwnership", "Own"); // ✅ Save home ownership status
                }}
              >
                Own
              </button>
            </div>
          </div>
        )}

        {/* ✅ Navigation Buttons */}
        <div className="mt-8 flex gap-4">
          <button
            onClick={() => handleNavigation("/birthdate-form")}
            className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
          >
            Back
          </button>

          <button
            onClick={() => handleNavigation("/email-form")}
            className={`px-6 py-3 rounded-lg w-40 transition-all duration-300 ${
              address && homeOwnership
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={!address || !homeOwnership}
          >
            Next Step
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddressForm;
