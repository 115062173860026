import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 md:px-12 lg:px-16">
        {/* Top Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start border-b border-gray-300 pb-8 mb-8 space-y-6 lg:space-y-0">
          {/* Left: Diversity & Privacy Links */}
          <div className="space-y-4 text-center lg:text-left">
            <a
              href="#"
              className="flex items-center space-x-3 text-gray-800 font-semibold hover:underline"
            >
              <span className="text-purple-600 text-xl">🛡️</span>
              <span>Learn more about our commitment</span>
            </a>
            <a
              href="#"
              className="flex items-center space-x-3 text-gray-800 font-semibold hover:underline"
            >
              <span className="text-blue-600 text-xl">🔒</span>
              <span>Your privacy choices</span>
            </a>
          </div>

          {/* Right: App Store & Social Media Links */}
          <div className="flex flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-8">
            {/* App Store Links */}
            <div className="flex space-x-4">
              <a
                href="https://apps.apple.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="Download on the App Store"
                  className="h-12 hover:opacity-80 transition duration-300"
                />
              </a>
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="Get it on Google Play"
                  className="h-12 hover:opacity-80 transition duration-300"
                />
              </a>
            </div>

            {/* Social Media Icons */}
            <div className="flex space-x-6">
              {[
                {
                  name: "Facebook",
                  url: "https://www.facebook.com",
                  icon: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
                },
                {
                  name: "X",
                  url: "https://www.x.com",
                  icon: "https://upload.wikimedia.org/wikipedia/commons/5/53/X_logo_2023_original.svg", // Official X logo
                },
                {
                  name: "Instagram",
                  url: "https://www.instagram.com",
                  icon: "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png",
                },
                {
                  name: "LinkedIn",
                  url: "https://www.linkedin.com",
                  icon: "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png",
                },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-80 transition duration-300"
                  aria-label={social.name}
                >
                  <img
                    src={social.icon}
                    alt={social.name}
                    className="h-8 w-8"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="text-sm text-gray-500 text-center lg:text-left space-y-4">
          <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mb-4">
            {[
              "Legal Terms & Conditions",
              "Privacy Center",
              "U.S. Data Privacy Policy",
              "Press",
              "Careers",
              "Contact Us",
            ].map((text, index) => (
              <a
                key={index}
                href="#"
                className="hover:text-gray-700 transition"
              >
                {text}
              </a>
            ))}
          </div>
          <p>&copy; 2025 USA Insurance Reviews. All rights reserved.</p>
          <p>
            USA Insurance Reviews and its trademarks are registered trademarks
            of USA Insurance Reviews and affiliates. Other company names are
            property of their owners.{" "}
            <a href="#" className="text-blue-600 hover:underline">
              Licenses and Disclosures
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
