// src/App.js
import React, { useEffect } from "react";
import Banner from "../src/components/UserFlowOne/Banner";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { getUrlParameter } from "./utils/getUrlParameter";
import CarInsurancePage from "./components/UserFlowOne/ZipCodePage";
import StepOne from "./components/UserFlowOne/StepOne";
import StepTwo from "./components/UserFlowOne/StepTwo";
import StepThree from "./components/UserFlowOne/StepThree";
import StepFour from "./components/UserFlowOne/StepFour";
import StepFive from "./components/UserFlowOne/StepFive";
import StepSix from "./components/UserFlowOne/StepSix";
import Summary from "./components/UserFlowOne/Summary";
import LoadingDeals from "./components/UserFlowOne/LoadingDeals";
import OffersPage from "./components/UserFlowOne/OffersPage";
import "./styles/flowOne/App.css";
import "./styles/flowOne/Form.css";
import Currentinsurer from "./components/UserFlowOne/CurrentInsurer";
import NameForm from "./components/UserFlowOne/NameForm.js";
import BirthdateForm from "./components/UserFlowOne/BirthdateForm.js";
import AddressForm from "./components/UserFlowOne/AddressForm.js";
import EmailForm from "./components/UserFlowOne/EmailForm.js";
import MobileNumberForm from "./components/UserFlowOne/MobileNumberForm.js";
import LoadingScreen from "./components/UserFlowOne/LoadingScreen.js";
import ConfirmAddressForm from "./components/UserFlowOne/ConfirmAddressForm.js";
import CarDetailsForm from "./components/UserFlowOne/CarDetailsForm.js";

// Flow Two
import ZipCodePage2 from "./components/UserFlowTwo/ZipCodePage2";
import StepOne2 from "./components/UserFlowTwo/StepOne2";
import StepTwo2 from "./components/UserFlowTwo/StepTwo2";
import StepThree2 from "./components/UserFlowTwo/StepThree2";
import LoadingDeals2 from "./components/UserFlowTwo/LoadingDeals2";
import OffersPage2 from "./components/UserFlowTwo/OffersPage2";
import { DeviceProvider } from "./contexts/DeviceContext.js";

function App() {
  return (
    <DeviceProvider>
      <Router>
        <PageRoutes />
      </Router>
    </DeviceProvider>
  );
}

function PageRoutes() {
  useEffect(() => {
    // Capture campaign parameters from URL
    const campaignName = getUrlParameter("campaign_name");
    const campaignId = getUrlParameter("campaign_id");
    const clickId = getUrlParameter("click_id");

    // Store the parameters in local storage if they are present
    if (campaignName) {
      localStorage.setItem("campaign_name", campaignName);
    }
    if (campaignId) {
      localStorage.setItem("campaign_id", campaignId);
    }
    if (clickId) {
      localStorage.setItem("click_id", clickId);
    }
  }, []);

  return (
    <Routes location={location}>
      {/* UserFlowOne routes */}
      <Route path="/" element={<CarInsurancePage />} />
      <Route path="/step-one" element={<StepOne />} />
      <Route path="/step-two" element={<StepTwo />} />
      <Route path="/step-three" element={<StepThree />} />
      <Route path="/step-four" element={<StepFour />} />
      <Route path="/step-five" element={<StepFive />} />
      <Route path="/step-six" element={<StepSix />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="/loading-deals" element={<LoadingDeals />} />
      <Route path="/offers" element={<OffersPage />} />
      <Route path="/current-insurer" element={<Currentinsurer />} />
      <Route path="/name-form" element={<NameForm />} />
      <Route path="/birthdate-form" element={<BirthdateForm />} />
      <Route path="/address-form" element={<AddressForm />} />
      <Route path="/email-form" element={<EmailForm />} />
      <Route path="/mobile-number-form" element={<MobileNumberForm />} />
      <Route path="/loading-screen" element={<LoadingScreen />} />
      <Route path="/confirm-address-form" element={<ConfirmAddressForm />} />
      <Route path="/car-details-form" element={<CarDetailsForm />} />
      {/* UserFlowTwo routes */}
      <Route path="/2" element={<ZipCodePage2 />} />
      <Route path="/step-one2" element={<StepOne2 />} />
      <Route path="/step-two2" element={<StepTwo2 />} />
      <Route path="/step-three2" element={<StepThree2 />} />
      <Route path="/loading-deals2" element={<LoadingDeals2 />} />
      <Route path="/offers2" element={<OffersPage2 />} />
    </Routes>
  );
}

export default App;
