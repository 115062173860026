
import React, { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import Header from "./Header";
import Footer from "./Footer";
import ProgressBar from "./ProgressBar";

const EmailForm = ({ onNext }) => {
  const steps = ["Current Insurer", "About You", "Quotes"];
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { isLoading, handleNavigation } = useNavigationHandler();

  // ✅ Load saved email from sessionStorage
  useEffect(() => {
    const savedEmail = sessionStorage.getItem("email");
    if (savedEmail) setEmail(savedEmail);
  }, []);

  // ✅ Save email to sessionStorage
  useEffect(() => {
    if (email) sessionStorage.setItem("email", email);
  }, [email]);

  // ✅ Email validation using regex
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError("Email address is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return false;
    }
    setError("");
    return true;
  };

  // ✅ Handle Input Change
  const handleChange = (e) => {
    setEmail(e.target.value);

    // Auto-validate while typing
    if (error && e.target.value) {
      validateEmail();
    }
  };

  // ✅ Handle Next Step
  const handleNext = () => {
    if (validateEmail()) {
      onNext();
      handleNavigation("/next-page"); // Navigate to the next page
    }
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[60vh] bg-white px-4 py-10 fade-in relative">
        <ProgressBar steps={steps} currentStep={2} progress={50} />

        <div className="text-center mt-6">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            What is your email address?
          </h1>
          <p className="text-gray-500 mt-2">
            You’ll receive your savings offer here.
          </p>
        </div>

        {/* ✅ Email Input Field */}
        <div className="mt-8 w-full max-w-sm relative">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleChange}
            onBlur={validateEmail}
            className={`w-full border rounded-lg px-4 py-3 text-gray-700 focus:outline-none text-lg ${
              error
                ? "border-red-500 focus:ring-2 focus:ring-red-500"
                : "border-pink-500 focus:ring-2 focus:ring-pink-500"
            }`}
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        {/* ✅ Membership Notice */}
        <div className="mt-6 w-full max-w-sm bg-gray-100 rounded-lg px-4 py-4 text-left text-sm leading-relaxed">
          <p className="text-gray-900 font-medium">Membership benefits await</p>
          <p className="text-gray-500 mt-2">
            After signing up to shop for insurance, you’ll also receive an email
            inviting you to access your free Experian Credit Report and FICO®
            Score, and other offers.
          </p>
        </div>

        {/* ✅ Buttons */}
        <div className="mt-8 flex gap-4">
          <button
            onClick={() => handleNavigation("/address-form")}
            className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
          >
            Back
          </button>

          <button
            onClick={() => handleNavigation("/mobile-number-form")}
            className={`px-6 py-3 rounded-lg w-40 transition-all duration-300 ${
              email && !error
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={!email || error}
          >
            Next Step
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EmailForm;
