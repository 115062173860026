import { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";

const DropdownBanner = () => {
  const { isLoading, handleNavigation } = useNavigationHandler(); // Get loading state and function
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update mobile state on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const targetButton = document.getElementById("get-started-btn");

    if (!targetButton) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      { threshold: 0.1 } // Triggers when at least 10% of the button is out of view
    );

    observer.observe(targetButton);

    return () => observer.disconnect(); // Cleanup on unmount
  }, []);

  return (
    <div
      id="dropdown-banner"
      className={`fixed w-full bg-gray-900 text-white py-3 md:py-4 shadow-md transition-transform transform ${
        isVisible
          ? "translate-y-0"
          : isMobile
          ? "translate-y-full"
          : "-translate-y-full"
      } ${isMobile ? "bottom-0" : "top-0"} z-50`}
    >
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center max-w-6xl mx-auto px-4 md:px-6">
        {/* Text Message */}
        <p className="text-sm md:text-lg text-center md:text-left">
          🚗 Stop overpaying for car insurance. Click below to get started!
        </p>

        {/* Button */}
        <button
          className="bg-pink-600 hover:bg-pink-500 text-white text-sm md:text-base px-5 py-2 rounded-lg mt-2 md:mt-0 md:ml-4 transition duration-300"
          onClick={() => handleNavigation("/current-insurer")}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default DropdownBanner;
