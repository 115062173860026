import React, { useState, useEffect } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

const NameForm = ({ onNext }) => {
  const { isLoading, handleNavigation } = useNavigationHandler();
  const steps = ["Current Insurer", "About You", "Quotes"];
  const [currentStep, setCurrentStep] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // ✅ Load from session storage
  useEffect(() => {
    const savedFirstName = sessionStorage.getItem("firstName");
    const savedLastName = sessionStorage.getItem("lastName");

    if (savedFirstName) setFirstName(savedFirstName);
    if (savedLastName) setLastName(savedLastName);
  }, []);

  // ✅ Save to session storage when updated
  useEffect(() => {
    sessionStorage.setItem("firstName", firstName);
  }, [firstName]);

  useEffect(() => {
    sessionStorage.setItem("lastName", lastName);
  }, [lastName]);

  // ✅ Enable button only when both fields are filled
  const isNextStepEnabled = firstName.trim() !== "" && lastName.trim() !== "";

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[60vh] bg-white px-4 py-10 fade-in relative">
        <ProgressBar steps={steps} currentStep={1} progress={30} />

        {/* Main Content */}
        <div className="text-center mt-6">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            What is your name?
          </h1>
          <p className="text-gray-500 mt-2">
            Please enter the name as listed on your driver license.
          </p>
        </div>

        {/* Input Fields */}
        <div className="mt-8 w-full max-w-sm space-y-4">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full border border-pink-300 rounded-lg px-4 py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 text-lg"
          />

          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-4 py-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-pink-400 text-lg"
          />
        </div>

        {/* Agreement */}
        <div className="text-center mt-6 text-gray-500 text-sm w-full max-w-md">
          By pressing <strong>“Next Step”</strong>, you accept and agree to our{" "}
          <a href="#" className="text-blue-600 underline">
            Terms of Use Agreement
          </a>{" "}
          as well as acknowledge receipt of our{" "}
          <a href="#" className="text-blue-600 underline">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="#" className="text-blue-600 underline">
            Disclosures
          </a>
          , and you agree to provide accurate and complete household
          information.
        </div>

        {/* Buttons */}
        <div className="mt-8 flex gap-4">
          <button
            onClick={() => handleNavigation("/current-insurer")} // ✅ Navigate to the previous page
            style={{ border: "1px solid #D1D5DB" }}
            className="border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-40 hover:bg-gray-100 transition-all duration-300"
          >
            Back
          </button>

          <button
            onClick={() => handleNavigation("/birthdate-form")} // ✅ Navigate to the next page)}
            className={`px-6 py-3 rounded-lg w-40 transition-all duration-300 ${
              isNextStepEnabled
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={!isNextStepEnabled}
          >
            Next Step
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default NameForm;
