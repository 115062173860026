import React, { useEffect, useState } from "react";
import Header from "../UserFlowOne/Header";
import Footer from "../UserFlowOne/Footer";
import "../../styles/flowOne/OffersPage.css";
import { CSSTransition } from "react-transition-group";

const OffersPage = () => {
  const [cityName, setCityName] = useState("your area");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // Extract all the parameters you need
    const zipCode =
      params.get("zip") || localStorage.getItem("zip_code") || "00000";
    const insured = params.get("insured") || localStorage.getItem("insured");
    const numVehicles =
      params.get("vehicles") || localStorage.getItem("numVehicles");
    const numDrivers =
      params.get("drivers") || localStorage.getItem("numDrivers");
    const homeOwnership =
      params.get("home_ownership") ||
      localStorage.getItem("home_ownership") ||
      "00000";
    const campaignName =
      params.get("campaign_name") ||
      localStorage.getItem("campaign_name") ||
      "default_campaign";
    const campaignId =
      params.get("campaign_id") ||
      localStorage.getItem("campaign_id") ||
      "default_id";
    const clickId =
      params.get("click_id") ||
      localStorage.getItem("click_id") ||
      "default_click";
    const dsp_name = params.get("dsp_name") || localStorage.getItem("dsp_name");

    // Create the ad configuration object
    const mediaAlphaConfig = {
      data: {
        zip: zipCode,
        currently_insured: insured,
        "vehicles[]": numVehicles,
        "drivers[]": numDrivers,
        home_ownership: homeOwnership,
      },
      placement_id:
        dsp_name === "google"
          ? "k7ImWb8FHOIBYxJQPmKSAUiyBQx_kg"
          : "fD4petSMP9HeM2IAYex88RKg0siJ_Q",

      sub_1: campaignName,
      sub_2: campaignId,
      sub_3: clickId,
      type: "ad_unit",
      version: 17,
    };

    // Function to load the MediaAlpha script dynamically
    const loadMediaAlphaScript = () => {
      return new Promise((resolve, reject) => {
        // Check if the script is already loaded
        if (document.getElementById("mediaalpha-script")) {
          resolve(); // If it's already loaded, just resolve immediately
          return;
        }

        const mediaAlphaScript = document.createElement("script");
        mediaAlphaScript.id = "mediaalpha-script";
        mediaAlphaScript.src = "//insurance.mediaalpha.com/js/serve.js";
        mediaAlphaScript.async = true;

        mediaAlphaScript.onload = () => {
          console.log("MediaAlpha script loaded successfully.");
          resolve(); // Script has been loaded successfully
        };
        mediaAlphaScript.onerror = (error) => {
          console.error("Failed to load MediaAlpha script.", error);
          reject(error); // There was an error loading the script
        };

        document.body.appendChild(mediaAlphaScript);
      });
    };

    // Load the MediaAlpha script and configure the ad
    const setupAdUnit = async () => {
      try {
        await loadMediaAlphaScript();

        // Wait for MediaAlphaExchange__load function to become available
        if (typeof window.MediaAlphaExchange__load === "function") {
          window.MediaAlphaExchange = mediaAlphaConfig;
          window.MediaAlphaExchange__load("mediaalpha_placeholder");
        } else {
          console.error("MediaAlphaExchange__load function is not defined.");
        }
      } catch (error) {
        console.error(
          "An error occurred while setting up the MediaAlpha ad:",
          error
        );
      }
    };

    setupAdUnit();

    // Add click event listener for conversion tracking
    const addConversionTracking = () => {
      const mediaalphaPlaceholder = document.getElementById(
        "mediaalpha_placeholder"
      );

      if (mediaalphaPlaceholder) {
        mediaalphaPlaceholder.addEventListener("click", () => {
          if (dsp_name === "google") {
            // Fire Google Conversion Pixel
            if (typeof window.gtag_report_conversion === "function") {
              window.gtag_report_conversion();
            } else {
              console.warn(
                "Google conversion tracking function is not available."
              );
            }
          } else {
            // Fire Facebook Lead Pixel
            if (typeof window.fbq === "function") {
              window.fbq("track", "Lead");
            } else {
              console.warn(
                "Facebook pixel tracking function is not available."
              );
            }
          }
        });
      }
    };

    // Add the conversion tracking functionality after the ad is loaded
    addConversionTracking();

    // Fetch city name from ZIP code
    if (zipCode !== "00000") {
      fetchCityName(zipCode);
    }

    // Star ratings function for offers
    const addStarRatings = () => {
      // Select the specific elements with the provided class names
      const offerElements = document.querySelectorAll(
        ".mav-partner.js-media-alpha-partner.mav-partner__standard.collapsible.collapsed, .mav-partner.js-media-alpha-partner.mav-partner__featured.collapsible.collapsed"
      );

      offerElements.forEach((offerElement, index) => {
        // Check if stars are already added to avoid duplicates
        if (offerElement.querySelector(".star-overlay")) {
          return; // If stars are already present, skip adding
        }

        // Create the star overlay
        const starOverlay = document.createElement("div");
        starOverlay.className = "star-overlay";
        starOverlay.setAttribute("data-rating", `offer-${index}`); // Adding an attribute for easier debugging

        // Set the star rating (start with 5 and decrement by 1 for each listing)
        let rating = 5 - index;
        if (rating < 1) {
          rating = 1; // Ensure that rating does not go below 1 star
        }

        // Add stars
        for (let i = 1; i <= 5; i++) {
          const star = document.createElement("span");
          star.className = "star" + (i <= rating ? " filled" : "");
          star.innerHTML = "★";
          starOverlay.appendChild(star);
        }

        // Append the star overlay to the offer card (the full container)
        offerElement.style.position = "relative"; // Ensure the offer container is positioned relative
        offerElement.appendChild(starOverlay);
      });
    };

    // Use MutationObserver to detect changes and call the function when MediaAlpha ads are loaded
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          console.log("Mutation detected:", mutation);
          addStarRatings();
        }
      });
    });

    const targetNode = document.getElementById("mediaalpha_placeholder");
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }
  }, []);

  const fetchCityName = async (zipCode) => {
    try {
      const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
      if (response.ok) {
        const data = await response.json();
        const city = data.places[0]["place name"];
        setCityName(city);
      }
    } catch (error) {
      console.error("Failed to fetch city name:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 py-8 md:py-12">
        {/* ✅ Sales Pitch (Mobile & Desktop Adjustments) */}
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-900 text-center mb-3">
          Exclusive Savings Found in {cityName}!
        </h2>
        <p className="text-sm sm:text-base text-gray-700 text-center mb-6">
          Top-rated insurance providers want your business. Get **better
          coverage for less** – but act fast, rates change daily!
        </p>

        {/* ✅ MediaAlpha Offers Placeholder */}
        <div id="mediaalpha_placeholder" className="w-full"></div>

        {/* ✅ Urgency & Trust Section */}
        <div className="mt-6 p-4 bg-blue-50 border border-blue-300 rounded-lg text-center">
          <h3 className="text-base sm:text-lg font-semibold text-blue-900 mb-1">
            Your Exclusive Offer is Waiting!
          </h3>
          <p className="text-xs sm:text-sm text-gray-700">
            These deals won’t last forever. Lock in today’s rates before
            they disappear.
          </p>
        </div>

        {/* ✅ Strong CTA for Mobile & Desktop */}
        <div className="mt-5 flex justify-center">
          <a
            href="#mediaalpha_placeholder"
            className="w-full sm:w-auto bg-pink-600 text-white font-semibold text-sm sm:text-base px-4 sm:px-6 py-3 rounded-lg shadow-lg hover:bg-pink-700 transition text-center"
          >
            View My Quote
          </a>
        </div>

        {/* ✅ Alternative Offer for Hesitant Users */}
        <div className="mt-6 p-4 bg-gray-100 border border-gray-300 rounded-lg text-center">
          <h3 className="text-base sm:text-lg font-semibold text-gray-900 mb-2">
            Haven’t found the right fit?
          </h3>
          <p className="text-xs sm:text-sm text-gray-600">
            Sign up for rate monitoring and get notified when we find a
            better deal for you.
          </p>
          <a
            href="#"
            className="block mt-2 text-pink-600 font-semibold hover:underline"
          >
            Create Account →
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OffersPage;