import React from "react";

const ProgressBar = ({ steps, currentStep, progress }) => {
  return (
    <div className="w-full max-w-3xl relative mb-10">
      {/* ✅ Step Labels (Above the Bar) */}
      <div className="flex justify-between text-gray-500 text-sm font-medium mb-2">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`text-center ${
              index < currentStep
                ? "text-blue-600 font-semibold"
                : index === currentStep
                ? "text-blue-800 font-semibold"
                : "text-gray-400"
            }`}
          >
            {step}
          </div>
        ))}
      </div>

      {/* ✅ Progress Line (With Smooth Transitions) */}
      <div className="w-full h-[2px] bg-gray-300 relative">
        <div
          className="h-full bg-blue-600 transition-all duration-500 ease-in-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
