import React, { useState, useEffect, useRef } from "react";
import useNavigationHandler from "../hooks/useNavigationHandler";
import ProgressBar from "./ProgressBar";
import Header from "./Header";
import Footer from "./Footer";

const insurers = [
  "21st Century Insurance",
  "Acuity Insurance",
  "Aflac",
  "Allianz Life",
  "Allied Insurance",
  "Allstate",
  "American Automobile Association (AAA)",
  "American Family Insurance",
  "American Income Life Insurance Company",
  "American International Group (AIG)",
  "American National Insurance Company",
  "American Strategic Insurance (ASI)",
  "Ameriprise Auto & Home Insurance",
  "Amica Mutual Insurance",
  "Auto-Owners Insurance",
  "Berkshire Hathaway",
  "Chubb",
  "Cincinnati Insurance Company",
  "Country Financial",
  "Erie Insurance",
  "Esurance",
  "Farmers Insurance",
  "Foremost Insurance Company",
  "GEICO",
  "Hanover Insurance",
  "Hartford Financial Services",
  "Infinity Insurance",
  "Kemper Corporation",
  "Liberty Mutual",
  "MAPFRE Insurance",
  "Mercury Insurance Group",
  "MetLife",
  "Nationwide",
  "Plymouth Rock Assurance",
  "Progressive",
  "SafeAuto",
  "Safeco Insurance",
  "State Auto Insurance",
  "State Farm",
  "The General",
  "The Travelers Companies",
  "USAA",
  "Westfield Insurance",
  "Other",
];

const CurrentInsurer = () => {
  const steps = ["Current Insurer", "About You", "Quotes"];
  const [currentStep, setCurrentStep] = useState(1);
  const { isLoading, handleNavigation } = useNavigationHandler();
  const [currentInsurer, setCurrentInsurer] = useState("");
  const [noInsurance, setNoInsurance] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  const filteredInsurers = insurers.filter((insurer) =>
    insurer.toLowerCase().includes(search.toLowerCase())
  );

  // ✅ Initialize state from session storage
  useEffect(() => {
    const savedInsurer = sessionStorage.getItem("selectedInsurer");
    const savedNoInsurance = sessionStorage.getItem("noInsurance");

    if (savedInsurer) setCurrentInsurer(savedInsurer);
    if (savedNoInsurance === "true") setNoInsurance(true);
  }, []);

  // ✅ Save currentInsurer to session storage
  useEffect(() => {
    if (currentInsurer) {
      sessionStorage.setItem("selectedInsurer", currentInsurer);
    } else {
      sessionStorage.removeItem("selectedInsurer");
    }
  }, [currentInsurer]);

  // ✅ Save noInsurance to session storage
  useEffect(() => {
    sessionStorage.setItem("noInsurance", noInsurance.toString());
  }, [noInsurance]);

  // ✅ Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      setIsVisible(true);
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => setIsOpen(false), 300);
  };

  // ✅ Enable button if currentInsurer or noInsurance is true
  const isNextStepEnabled = currentInsurer || noInsurance;

  return (
    <div>
      <Header />
      <div className="flex flex-col items-center justify-top min-h-[80vh] bg-white px-4 py-10 fade-in relative">
        <ProgressBar steps={steps} currentStep={0} progress={15} />

        {/* Main Content */}
        <div className="text-center mt-10">
          <h1 className="text-3xl md:text-4xl font-semibold text-gray-900">
            What is your current auto <br /> insurance company?
          </h1>
          <p className="text-gray-500 mt-2">
            We’ll compare quotes to find you a better policy.
          </p>
        </div>

        {/* Dropdown Button */}
        <div className="mt-6 relative">
          <button
            onClick={() => setIsOpen(true)}
            style={{ border: "1px solid #D1D5DB" }}
            className="w-full border border-black bg-white text-gray-700 px-10 py-3 rounded-lg w-80 md:w-auto hover:bg-gray-100 transition-all duration-300"
          >
            {currentInsurer || "Select your insurance company"}
          </button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-gray-500 absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>

        {/* Buttons */}
        <div className="mt-6 flex flex-col md:flex-row gap-4">
          <button
            className={`border border-gray-700 text-gray-700 px-6 py-3 rounded-lg w-70 md:w-auto hover:bg-gray-100 transition-all duration-300 ${
              noInsurance ? "bg-gray-100" : "hover:bg-gray-100"
            }`}
            style={{ border: "1px solid rgb(137, 141, 146)" }}
            onClick={() => {
              setNoInsurance(!noInsurance);
              setCurrentInsurer("");
            }}
          >
            I Don’t Have Insurance
          </button>

          <button
            className={`px-6 py-3 rounded-lg w-80 md:w-auto transition-all duration-300 ${
              isNextStepEnabled
                ? "bg-pink-500 text-white hover:bg-pink-600"
                : "bg-pink-300 text-white cursor-not-allowed"
            }`}
            disabled={!isNextStepEnabled}
            onClick={() => handleNavigation("/name-form")}
          >
            Next Step
          </button>
        </div>
      </div>

      <Footer />

      {/* Modal Dropdown */}
      {isOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
            isVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            ref={modalRef}
            className={`bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative animate-slide-up ${
              isVisible ? "modal-enter" : "modal-exit"
            }`}
          >
            {/* Search Input */}
            <div className="flex items-center border border-gray-300 rounded-lg p-2 mb-4">
              {/* Search Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-gray-500 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35m1.35-4.65a6 6 0 11-12 0 6 6 0 0112 0z"
                />
              </svg>
              <input
                type="text"
                placeholder="Search for your current company"
                className="w-full outline-none bg-transparent"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <p className="text-gray-600 mb-2">Most popular:</p>

            {/* Insurance Options */}
            <div className="max-h-64 overflow-y-auto">
              {filteredInsurers.map((insurer) => (
                <button
                  key={insurer}
                  className={`w-full text-left px-4 py-3 rounded-lg mb-2 ${
                    currentInsurer === insurer
                      ? "bg-blue-200 text-blue-900"
                      : "bg-gray-100 hover:bg-gray-200"
                  } transition-all duration-300`}
                  onClick={() => {
                    setCurrentInsurer(insurer);
                    setNoInsurance(false);
                    handleClose();
                  }}
                >
                  {insurer}
                </button>
              ))}
            </div>

            {/* Search Instead Link */}
            <div className="text-center mt-4">
              <p className="text-gray-600">Don’t see your Insurance Company?</p>
              <a href="#" className="text-blue-600 underline">
                Search instead
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentInsurer;
