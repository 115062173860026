import DropdownBanner from "./DropdownBanner.js"; // Import the fixed component
import Header from "./Header";
import Hero from "./Hero";
import Logos from "./Logos";
import SameCoverageForLess from "./SameCoverageForLess.js";
import HowItWorks from "./HowItWorks";
import Reviews from "./Reviews.js";
import ChoosePolicies from "./ChoosePolicies.js";
import FAQSection from "./FAQSection";
import Footer from "./Footer";

const CarInsurancePage = () => {

  return (
    <div className="min-h-screen bg-white fade-in relative min-h-screen">
      {/* Dropdown Banner */}
      <DropdownBanner />
      {/* Header Section */}
      <Header />
      {/* Hero Section */}
      <Hero />
      {/* Logos Section */}
      <Logos />
      {/* Same Coverage For Less Section */}
      <SameCoverageForLess />
      {/* How It Works Section */}
      <HowItWorks />
      {/* Reviews Section */}
      <Reviews />
      {/* Choose Policies Section */}
      <ChoosePolicies />
      {/* FAQ Section */}
      <FAQSection />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default CarInsurancePage;
