import React from "react";

const SameCoverageForLess = () => {
  return (
    <div>
      <div className="h-2 rounded-t-lg mx-auto max-w-7xl bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500"></div>
      <section className="bg-gray-50 rounded-lg shadow-md p-6 md:p-10 mx-auto max-w-7xl text-center">
        <h2 className="text-3xl md:text-4xl font-semibold mb-6 text-black text-center">
          Find Your Same Coverage for Less
        </h2>
        <p className="text-base md:text-xl text-gray-800 mb-8">
          You may be overpaying for car insurance, and shopping for it is a
          hassle. That’s where we come in.
        </p>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col items-center bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3917/3917512.png"
              alt="Compare Icon"
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-lg font-semibold mb-2">Compare</h3>
            <p className="text-gray-600 text-center">
              We actively monitor rates to ensure you’re always getting the best
              deal.
            </p>
          </div>
          <div className="flex flex-col items-center bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3914/3914072.png"
              alt="Save Time Icon"
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-lg font-semibold mb-2">Save Time</h3>
            <p className="text-gray-600 text-center">
              No need to visit other sites—we handle it all in one place.
            </p>
          </div>
          <div className="flex flex-col items-center bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3916/3916595.png"
              alt="Peace of Mind Icon"
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-lg font-semibold mb-2">Peace of Mind</h3>
            <p className="text-gray-600 text-center">
              Rest easy knowing you have the right price. We never sell your
              information.
            </p>
          </div>
          <div className="flex flex-col items-center bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition">
            <img
              src="https://cdn-icons-png.flaticon.com/128/7928/7928164.png"
              alt="Potential Savings Icon"
              className="w-12 h-12 mb-4"
            />
            <h3 className="text-lg font-semibold mb-2">Potential Savings</h3>
            <p className="text-gray-600 text-center">
              Let us find you a lower rate—you may even get a partial refund
              from your previous plan!
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SameCoverageForLess;
