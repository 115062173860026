import React, { useState } from "react";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What makes us different?",
      answer:
        "USA Insurance Reviews Services® acts as your online insurance broker and agent. We don't replace the insurance company, we just help you shop and switch to a policy that's best for you—for free!",
    },
    {
      question: "How do we make money?",
      answer: `Just like all agents, we are paid a commission by the insurance company. There is no upcharge to you, and Experian Insurance Services® is a free tool for you to use. These commissions are from the insurance companies' marketing budgets given we brought them new customers, not from your premiums. Your rates are the rates provided and aren't impacted by our commission.\n\nWe show you the best available quotes based on your specific coverage. We're unbiased and earn commissions regardless of which policy you choose.\n\nWe will never sell your data and we don't cold call or spam our customers.`,
    },
    {
      question: "Are we licensed?",
      answer:
        "Yes, we are licensed in all 50 states, as well as Washington DC. You can view all licenses here.",
    },
    {
      question: "How do you get a refund on your old policy?",
      answer:
        "After switching your coverage, USA Insurance Reviews Services® can help you send a cancelation notice to your original insurance carrier, which may trigger a partial refund from your previous policy. Refunds are calculated based on the unused length of your original insurance policy and are handled directly by the original carrier.",
    },
  ];

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-6 md:px-12 lg:px-16">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 text-center mb-8">
          You have questions, we have answers
        </h2>

        {/* FAQ Container */}
        <div className="bg-white rounded-lg shadow-lg divide-y divide-gray-200">
          {faqs.map((faq, index) => (
            <div key={index} className="group">
              {/* Question */}
              <button
                className="w-full flex justify-between items-center py-5 px-6 text-left text-lg font-semibold text-gray-900 focus:outline-none hover:bg-gray-100 transition-all"
                onClick={() => toggleAccordion(index)}
              >
                <span>{faq.question}</span>
                <span
                  className={`transform transition-transform duration-300 text-xl font-bold ${
                    activeIndex === index
                      ? "rotate-180 text-blue-600"
                      : "text-gray-700"
                  }`}
                >
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>

              {/* Answer */}
              <div
                className={`overflow-hidden transition-all duration-500 ${
                  activeIndex === index ? "max-h-96 py-4 px-6" : "max-h-0"
                }`}
              >
                <p className="text-gray-700 text-base whitespace-pre-line">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Footer Note */}
        <div className="mt-10 bg-white rounded-lg shadow-md p-6 text-gray-600 text-sm leading-relaxed">
          <p>
            We receive a commission, and possibly other performance-based
            compensation, from insurance companies for our services. You may ask
            for more information about commissions by emailing us at{" "}
            <a
              href="mailto:advisors@usainsurance.com"
              className="text-blue-800 underline hover:text-blue-600"
            >
              advisors@usainsurance.com
            </a>
            . Some products are not available in certain geographies. Quotes are
            subject to approval by the insurance carrier.{" "}
            <a href="#" className="text-blue-800 underline hover:text-blue-600">
              Click here to view our licenses.
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
