import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useNavigationHandler = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pathToNavigate, setPathToNavigate] = useState(null);

  // Function to apply fade-out effect
  const applyFadeEffect = () => {
    document.body.classList.add("fade-out");
    document.body.addEventListener(
      "transitionend",
      () => document.body.classList.remove("fade-out"),
      { once: true }
    );
  };

  // Effect to handle navigation
  useEffect(() => {
    if (pathToNavigate) {
      navigate(pathToNavigate);

      // Delay scroll-to-top to ensure the new page is fully loaded
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100); // Adjust delay if needed

      setPathToNavigate(null);
      setIsLoading(false);
    }
  }, [pathToNavigate, navigate]);

  const handleNavigation = (path) => {
    if (isLoading) return; // Prevent multiple clicks

    setIsLoading(true);
    applyFadeEffect();

    setTimeout(() => {
      setPathToNavigate(path);
    }, 300);
  };

  return { isLoading, handleNavigation };
};

export default useNavigationHandler;
